<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12
      .card
        .card-body
          //- .header-row.mb-3.row.text-right
            .col
              b-button.width-lg(variant="primary" to="/election-editor") ＋　新增選舉頁面
          //- Table
          .table-responsive.mb-0
            b-table(:items="getElectionPartys", :fields="fields", responsive="sm", :per-page="perPage", :current-page="currentPage", :sort-desc.sync="sortDesc", :filter="filter", :filter-included-fields="filterOn", @filtered="onFiltered")
              //- index
              template(v-slot:cell(index)="row") {{ row.index + 1 }} 
              template(v-slot:cell(logo)="row")
                img.max-width-30(v-if="row.item.logo", :src="row.item.logo", alt="alt")
                span(v-else) -
              template(v-slot:cell(status)="row") 
                span 有效
              template(v-slot:cell(count)="row")
                input.form-control.max-width-150.auto-center.text-center(v-model="row.item.count",@change="setCount(row.item.id,row.item.count)")
              //- 編輯
              //- template(v-slot:cell(edit)="row")
                b-button.table-btn.mr-2(to="/election-editor")
                  | 編輯
                //- b-button.table-btn.mr-2(v-if="!row.item.status")
                  | 上架
                //- b-button.ml-2(variant="danger" size="sm")
                  i.fe-trash-2 
          .row.mb-md-2
            .col-12.row.d-flex.justify-content-end  
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  // pagination
                  b-pagination(v-model="currentPage", :total-rows="rows", :per-page="perPage")

</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

/**
 * Starter component
 */
export default {
  data () {
    return {
      currentId: false,
      rows: 0,
      perPage: 1,
      currentPage: 1,
      title: "政黨列表",
      items: [
        {
          text: "選舉頁面專區",
          href: "/election",
        },
        {
          text: "政黨列表",
          active: true,
        }
      ],
      search_data: {
        status: 0,
      },
      value: '',
      daterange: '',
      visible: true,
      options: [],
      elections: [],
      tableData: [
        {
          id: "4NQW9mWgXA",
          name: "2014九合一大選",
          type: 2,
          poll_date: "2014-11-29 00:00:00",
          created_at: "2022-08-20 23:35:11",
          creater: [],
          updated_at: "2022-09-28 15:34:44",
          editer: {
            id: "jK3NKqZ8n5",
            name: "中天快點TV"
          },
          status: 0,
          use_model: 1,
          current: false
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      fields: [
        {
          key: "index",
          label: "項次",
          class: "width-auto center"
        },
        {
          key: "name",
          label: "政黨名稱",
          class: "width-auto center"
        },
        {
          key: "logo",
          label: "政黨Logo",
          class: "width-auto center"
        },
        {
          key: "txt_color",
          label: "政黨色碼",
          class: "width-auto center"
        },
        {
          key: "status",
          label: "狀態",
          class: "width-auto center"
        },
        {
          key: "count",
          label: "支持值",
          class: "width-auto center"
        },
        // {
        //   key: "edit",
        //   label: "操作",
        //   class: "width-auto center"
        // },
      ],
      tab: 1
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows () {
      return this.tableData.length;
    },
  },
  mounted () {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  },
  created () {
    if (this.$route.params.id) {
      this.currentId = this.$route.params.id
    }

  },
  methods: {
    getElectionPartys (_table, _callback) {
      let vm = this
      let SearchOptions = {
        page: this.currentPage
      }

      this.$root.apis.getElectionPartys(SearchOptions, async function (_response) {
        let parts = _response.body.data
        vm.currentPage = 1
        vm.rows = parts.length
        vm.perPage = parts.length

        if (typeof _callback === 'function') {
          await _callback(parts)
        }
      })
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    changeTab (_status) {
      this.tab = _status
    },
    setCount (_targetId, _count) {
      if (_count < 0) {
        alert("點火值不可以低於 0 哦！")
        return
      }

      console.log('==> 對指定政黨[' + _targetId + ']修改支持值:', _count)
      this.$root.apis.setPartyCount({
        partys: [_targetId],
        type: 3,
        value: parseInt(_count)
      }, function (_response) {
        alert("政黨支持值修改成功")
      }, function (_response) {

      })
    }
  }
};
</script>

<style lang="sass" scoped>
.set-content
  .form-horizontal
    display: flex
    justify-content: space-between
    align-items: center
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  justify-content: space-between
  align-items: center
  .header-title
    margin: 0
    flex: 1
.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)
::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
</style>
